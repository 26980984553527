/*!
 * thermos - index.js
 * @generated Wed Oct 10 2018 17:25:42 GMT+0900 (東京 (標準時))
 */
'use strict';

/* !
 * jQuery index v1.0.0
 * Copyright 2018, Connecty Inc.
 */
(function ($, THERMOS, cssua) {
	var arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow"></button>';
	var arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow"></button>';

	// メインビジュアル
	$('.idx-mainVis-slider').slick({
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		variableWidth: true,
		centerMode: true,
		arrows: false,
		dots: true,
		responsive: [{
			breakpoint: 769,
			settings: {
				variableWidth: false,
				centerMode: false
			}
		}]
	});

	// NEW PRODUCTS
	$('.idx-newProd-slider').slick({
		infinite: true,
		speed: 600,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: true,
		slidesToShow: 3,
		variableWidth: true,
		centerMode: true,
		prevArrow: arrowPrev,
		nextArrow: arrowNext,
		responsive: [{
			breakpoint: 769,
			settings: {
				slidesToShow: 2,
				variableWidth: false,
				centerMode: false
			}
		}]
	});

	// NEW CONTENTS
	if (THERMOS.va.window.width > 1024) {
		$('.idx-newContents-slider').slick({
			infinite: true,
			speed: 500,
			arrows: true,
			slidesToShow: 4,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			responsive: [{
				breakpoint: 769,
				settings: {
					slidesToShow: 2
				}
			}]
		});
	}

	// THERMOS TODAY
	// $('.idx-today-slider').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	speed: 500,
	// 	arrows: false,
	// 	slidesToShow: 5,
	// 	variableWidth: true,
	// 	centerMode: true,
	// 	responsive: [
	// 		{
	// 			breakpoint: 769,
	// 			settings: {
	// 				slidesToShow: 3,
	// 			}
	// 		}
	// 	]
	// });
})(window.jQuery, window.THERMOS, window.cssua);